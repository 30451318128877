<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-row class="m-2 top-header">
      <!-- Date picker -->
      <b-col cols="12" md="6" class="d-flex align-items-center">
        <h3 class="pr-1 mb-0">
          HATCHER
          <span class="ml-1 success-text"
            >{{ activeCount }} Active and {{ inactiveCount }} Inactive</span
          >
        </h3>
      </b-col>
      <!-- Search -->
      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-end align-items-center"
      >
        <div>
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            placeholder="Search..."
          />
        </div>
        <b-button variant="primary" v-b-modal.addHatcher class="mr-1 ml-2">
          Add
        </b-button>
      </b-col>
    </b-row>
    <div v-if="machine_info != null && machine_info.length > 0">
      <div v-for="info in machine_info" v-bind:key="info.id">
        <HatcherList
          :hatchery_device_id="
            info.hatchery_device ? info.hatchery_device.id : 0
          "
          :id="info.id"
          :hatcherName="info.machine_id"
          :deviceName="
            info.hatchery_device ? info.hatchery_device.device_id : 'N/A'
          "
          :humidity="info.hatchery_device ? info.hatchery_device.humidity : 0"
          :temperature="info.hatchery_device ? info.hatchery_device.temp : 0"
          :availableEgg="info.available_egg_capacity"
          :trayCapacity="info.tray_capacity"
          :eggCapacity="info.egg_capacity"
          :status="info.machine_status"
        />
      </div>
    </div>
    <div v-else class="d-flex flex-column align-items-center py-5">
      <b-img
        :src="require('@/assets/images/svg/machine/no-setter.svg')"
        class="mr-2"
      />
      <h4 class="mt-2 Secondary">No hatcher machine added yet</h4>
    </div>
    <!-- hatcher modal -->
    <b-modal
      id="addHatcher"
      title="Add Hatcher Details"
      size="lg"
      ok-title="Submit"
      @ok="addHatcherMachine()"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label-for="i-first-name" label="Hatcher ID">
          <b-form-input
            id="hatcher_name"
            placeholder=""
            v-model="machineData.machine_id"
          />
        </b-form-group>

        <b-form-group label-for="egg-capacity" label="Egg Capacity">
          <b-form-input
            id="egg_capacity"
            placeholder=""
            v-model="machineData.egg_capacity"
          />
        </b-form-group>

        <b-form-group label-for="No. of tray" label="No of tray">
          <b-form-input
            id="tray_no"
            placeholder=""
            v-model="machineData.tray_capacity"
          />
        </b-form-group>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormDatepicker,
  BImg,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import HatcherList from "../useHatcherList.vue";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BTooltip,
    BFormDatepicker,
    vSelect,
    HatcherList,
    BImg,
  },
  data() {
    return {
      hatchery_Device_id: null,
      temperature: null,
      humidity: null,
      searchQuery: null,
      machine_info: null,
      device_id: null,
      machineType: "hatcher",
      inactiveCount: null,
      activeCount: null,
      machineData: {
        machine_id: null,
        egg_capacity: null,
        tray_capacity: null,
        machine_type: "hatcher",
      },
    };
  },
  watch: {
    deep: true,
    immediate: true,
    getfarmId() {
      this.farmId = this.$route.params.farmId;
      this.getHatcherMachine();
    },
  },
  methods: {
    getHatcherMachine() {
      const farmId = this.$route.params.farmId;
      const machineType = "hatcher";
      axiosIns
        .get(`web/hatchery/${farmId}/getMachine/${machineType}`)
        .then((response) => {
          this.machine_info = response.data.all_machine;

          this.inactiveCount = response.data.inactiveCount;
          this.activeCount = response.data.activeCount;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    // getHatcherID() {
    // 	axiosIns
    // 		.get(`web/machine/setter/generate-machine-code`)
    // 		.then((response) => {
    // 			this.machineData.machine_id = response.data.machineCode;
    // 		})
    // 		.catch((error) => {
    // 			error;
    // 		});
    // },
    addHatcherMachine() {
      let formData = new FormData();
      this.machineData.hatchery_id = this.$route.params.farmId;
      let form_data = this.objectToFormData(this.machineData, formData);

      axiosIns
        .post(`web/add-machine`, form_data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "success",
              text: `Machine Successfully Added `,
            },
          });
          this.getHatcherMachine();
        })
        .catch((error) => {
          if (error.response.status == 400) {
            var data = error.response.data;
          } else {
            var data = error.response.data.errors;
          }
          let arr = Object.values(data).flat().toString(",");

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: arr,
            },
          });
        });
    },
  },

  created() {
    // this.getHatcherID();
    this.farmId = this.$route.params.farmId;
    if (this.farmId) {
      this.getHatcherMachine();
    } else {
      // alert("Select Poultrac");
    }
  },
};
</script>

<style lang="scss">
.top-header {
  border-bottom: 1px solid #296db4;
  padding: 1rem 0rem;
}
.success-text {
  color: rgba(41, 109, 180, 1);
  font-size: 14px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
